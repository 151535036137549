<template>
  <div class="container layout-sidebar">
    <div class="layout-sidebar-two-columns-container">
      <div class="layout-sidebar__sidebar unit-page__sidebar">
        <div class="card">
          <section class="card__section card-row unit-card__header">
            <Avatar
              :name="unit.acronym"
              class="card-row__figure figure--letter"
            />
            <div class="card-row__text">
              <div
                v-if="extinct"
                class="label warning-box__badge label--primary label--compact"
              >
                {{ $t('unit.public.profile.intro.card.extinct') }}
              </div>
              <span class="unit__name h4--ssp">
                {{ unit.name }}
              </span>
              <div class="unit__date">
                <p>
                  {{ $t(`unitTypes.${unit.type}`) }}
                </p>
                <p
                  v-if="unit.start?.year"
                >
                  {{ unit.start.year }} - {{ unit.end?.year || $t('present') }}
                </p>
              </div>
              <p class="unit__link--desktop ellipsize">
                <a :href="'mailto:' + unit.email">
                  {{ unit.email }}
                </a>
              </p>
              <p class="unit__website--desktop unit__link--desktop break-all">
                <a
                  :href="unit.website"
                  target="_blank"
                  rel="noreferrer"
                >
                  {{ displayWebsite }}
                </a>
              </p>
              <div class="unit__links--mobile">
                <span class="unit__link--mobile">
                  <a :href="'mailto:' + unit.email">
                    {{ $t('unit.public.profile.intro.card.email') }}
                  </a>
                </span>

                <span class="unit__link--mobile">
                  <a
                    :href="unit.website"
                    rel="noreferrer"
                    target="_blank"
                  >
                    {{ $t('unit.public.profile.intro.card.website') }}
                  </a>
                </span>
              </div>
            </div>
            <div class="card-row__meta">
              <button
                :aria-label="$t(`unit.public.profile.intro.card.toggle.aria-label.${isCardExpanded ? 'hide' : 'expand'}`)"
                class="mobile-expandable-card-section-toggle-trigger"
                :class="{ 'mobile-expandable-card-section-toggle-trigger--open': isCardExpanded }"
                @click.prevent="isCardExpanded = !isCardExpanded"
              />
            </div>
          </section>
          <section
            v-show="unit.bio"
            :class="{ 'mobile-expandable-card-section--mobile-visible': isCardExpanded }"
            class="card__section mobile-expandable-card-section"
          >
            <p class="unit__bio small">
              {{ unit.bio }}
            </p>
          </section>
        </div>
        <div class="card">
          <section
            :class="{ 'mobile-expandable-card-section--mobile-visible': isCardExpanded }"
            class="card__section mobile-expandable-card-section"
            style="min-height: 258px;"
          >
            <h1 class="card__section-title h5--ssp">
              {{ $t('unit.public.profile.intro.card.scientific.production') }}
            </h1>
            <ScientificProductionChart
              :data="scientificProduction"
            />
          </section>
        </div>
        <div
          v-if="!(unit.predecessorUnits.length === 0 && unit.successorUnits.length === 0)"
          class="card"
        >
          <section
            :class="{ 'mobile-expandable-card-section--mobile-visible': isCardExpanded }"
            class="card__section mobile-expandable-card-section"
          >
            <h1 class="card__section-title h5--ssp">
              {{ $t('unit.public.profile.intro.card.related.units') }}
            </h1>
            <div
              v-for="(successor, i) in unit.successorUnits"
              :key="i"
            >
              <router-link
                :to="{ name: 'UnitPage', params: { unitID: successor.id } }"
              >
                <u>{{ successor.name }}</u>
              </router-link>
              <p v-if="successor.start?.year">
                {{ successor.start.year }} - {{ successor.end?.year || $t("present") }}
              </p>
            </div>
            <div
              v-for="(predecessor, i) in unit.predecessorUnits"
              :key="i"
            >
              <router-link
                :to="{ name: 'UnitPage', params: { unitID: predecessor.id } }"
              >
                <u>{{ predecessor.name }}</u>
              </router-link>
              <p v-if="predecessor.start?.year">
                {{ predecessor.start.year }} - {{ predecessor.end?.year || $t("present") }}
              </p>
            </div>
          </section>
        </div>
      </div>
      <main class="layout-sidebar__main">
        <div class="tab-navigation">
          <ul class="tab-navigation__list">
            <li class="tab-navigation__list-item">
              <RouterLink
                :to="{ name: 'UnitRecordsPage', params: { unitID: unit.id } }"
                active-class="tab-navigation__list-item--selected"
                exact-active-class="tab-navigation__list-item--selected"
              >
                <span class="tab-navigation__link">
                  {{ $t('unit.public.profile.tab.records') }}
                </span>
              </RouterLink>
            </li>
            <li class="tab-navigation__list-item">
              <RouterLink
                :to="{ name: 'UnitMembersPage', params: { unitID: unit.id } }"
                active-class="tab-navigation__list-item--selected"
              >
                <span class="tab-navigation__link">
                  {{ $t('unit.public.profile.tab.members') }}
                </span>
              </RouterLink>
            </li>
            <li class="tab-navigation__list-item">
              <RouterLink
                v-if="unit.hasSubUnits"
                :to="{ name: 'UnitSubunitsPage', params: { unitID: unit.id } }"
                active-class="tab-navigation__list-item--selected"
                exact-active-class="tab-navigation__list-item--selected"
              >
                <span class="tab-navigation__link">
                  {{ $t('unit.public.profile.tab.subunits') }}
                </span>
              </RouterLink>
            </li>
          </ul>
        </div>
        <RouterView />
      </main>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/utils/Avatar.vue'
import ScientificProductionChart from '@/components/charts/ScientificProduction.vue'

import useNavigationGuards, { guardWithErrorHandling } from '@/router/navigation-guards'
import useUnitsAPI from '@/api/units'
import { mapState, useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useHead, useHeadSafe } from '@unhead/vue'

export default {
  components: {
    ScientificProductionChart,
    Avatar
  },
  inject: ['$axios'],
  beforeRouteUpdate: guardWithErrorHandling(
    async function (to, from, next) {
      if (to.params.unitID === this.unit.id) {
        to.meta.unit = this.unit
        to.meta.scientificProduction = this.scientificProduction
        return next()
      }
      const [unit, scientificProduction] = await Promise.all([
        useNavigationGuards(this.$axios).fetchUnit({ id: to.params.unitID }),
        this.UnitsAPI.getScientificProduction({ unitID: to.params.unitID })
      ])
      const predecessors = await Promise.all(unit.predecessorUnits.map(predecessor => useNavigationGuards(this.$axios).fetchUnit({ id: predecessor })))
      const successors = await Promise.all(unit.successorUnits.map(successor => useNavigationGuards(this.$axios).fetchUnit({ id: successor })))
      to.meta.scientificProduction = scientificProduction
      to.meta.unit = unit
      to.meta.unit.predecessorUnits = predecessors
      to.meta.unit.successorUnits = successors
      next()
    }
  ),
  props: {
    unit: {
      required: true,
      type: Object
    },
    scientificProduction: {
      type: Array,
      required: true
    }
  },
  setup (props) {
    const { t } = useI18n({
      fallbackWarn: false,
      silentTranslationWarn: true,
      silentFallbackWarn: true,
      messages: {
        pt: {
          present: 'presente'
        },
        en: {
          present: 'present'
        }
      }
    })
    const { state } = useStore()

    useHeadSafe({
      titleTemplate: `${props.unit.name} – %s`
    })
    useHead({
      script: [{
        type: 'application/ld+json',
        innerHTML: JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'Organization',
          name: props.unit.name,
          description: props.unit.bio,
          email: props.unit.email,
          logo: state.configuration.unitLdJsonDefaultLogo,
          url: props.unit.website,
          parentOrganization: props.unit.parentID
            ? {
                '@type': 'Organization',
                identifier: props.unit.parentID
              }
            : undefined
        })
      }]
    })
    return { t }
  },
  data () {
    return { UnitsAPI: useUnitsAPI(this.$axios), isCardExpanded: false }
  },
  computed: {
    ...mapState({ configuration: state => state.configuration }),
    displayWebsite () {
      if (!this.unit.website) return undefined
      const regex = /^https?:\/\/(?<name>.+)$/
      return this.unit.website.match(regex)?.groups?.name ?? this.unit.website
    },
    extinct () {
      return this.unit.end?.year < new Date().getFullYear()
    }
  }
}
</script>

<style lang="scss" scoped>
@use "@/assets/scss/variables" as *;

.unit-page__sidebar {
  position: relative;
}
.mobile-expandable-card-section {
  width: 100%;
}

.label--compact {
  width: fit-content;
  margin-bottom: 0.5rem;
  @media screen and (width >= 60rem) {
    margin-left: auto;
    margin-right: auto;
  }
}

.unit__date {
  p {
    margin-top: 0;
  }
}

.unit-card__header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
  align-items: flex-start;

  @media screen and (width <= 60rem) {
    .unit__date {
      display: flex;
      flex-flow: row wrap;
      ::after {
        content: "⸱";
        padding-left: 0.2rem;
        padding-right: 0.2rem;
      }
      :last-child::after {
        content: "";
      }
    }
    .unit__links--mobile {
      display: block;
    }
    .unit__link--desktop {
      display: none;
    }
    .card-row__figure {
      // extends figure--56
      min-width: 3.5rem;
      width: 3.5rem;
      min-height: 3.5rem;
      height: 3.5rem;
    }
  }

  @media screen and (width >= 60rem) {
    flex-flow: column nowrap;
    text-align: center;

    .unit__links--mobile {
      display: none;
    }
    .unit__link--desktop {
      display: block;
    }
    .card-row__figure {
      margin: 0 auto 1rem;

      // extends figure--80
      min-width: 5rem;
      width: 5rem;
      min-height: 5rem;
      height: 5rem;
    }
  }

  .card-row__text {
    max-width: 100%;
    overflow: hidden;

    .ellipsize {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .break-all {
      word-break: break-all;
    }
  }

  .card-row__meta {
    align-self: baseline;
  }
}

.unit__links--mobile {
  margin-top: 0.5rem;
}
.unit__link--mobile + .unit__link--mobile {
  margin-left: 0.75rem;
}
.unit__link--mobile a,
.unit__website--desktop a {
  color: $blue;
  font-weight: 600;
}

.unit__name {
  font-family: $title-font;
  font-weight: 500;
  margin: 0;

  @media screen and (width >= 60rem) {
    // extends h3
    font-size: 1.4375rem;
    color: $dark;
    font-weight: 500;
    line-height: 120%;
    margin-top: 0;
    margin-bottom: 4px;
  }
}

.unit__bio {
  white-space: pre-line;
  text-align: start;
}

</style>
